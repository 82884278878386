import { usePaginationPage } from '@/hooks/usePaginationPage';
import {
  createInvitationCodeApi,
  getInvitationCodeListApi,
  getMemberInvitationInformationApi,
  modifyInvitationCodeApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useMount } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';

export default () => {
  const { t } = useTranslation();
  //获取列表
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 10,
  });
  const { list, loading, loadMore, error, total, ...resetPageProps } =
    usePaginationPage({
      fn: getInvitationCodeListApi,
      params,
      setParams,
    });
  const reload = () => {
    setParams({
      pageNo: 1,
      pageSize: 10,
    });
  };
  //获取当前会员返佣比例
  const {
    runAsync: getMemberInvitationInformationReq,
    data: memberInvitationInformationData,
  } = useReq(getMemberInvitationInformationApi, {
    manual: true,
  });
  useMount(() => {
    getMemberInvitationInformationReq();
  });

  //新增表单
  const [recommendForm, setRecommendForm] = useState({
    remark: '',
    rebateRatio: 1,
    defaultCommend: false,
  });
  const { runAsync: createInvitationCodeReq } = useReq(
    createInvitationCodeApi,
    {
      manual: true,
      onSuccess() {
        toast.success(t('添加成功'));
        reload();
        setRecommendForm({
          remark: '',
          rebateRatio: 1,
          defaultCommend: false,
        });
      },
    },
  );
  //修改备注或者默认
  const { runAsync: modifyInvitationCodeReq } = useReq(
    modifyInvitationCodeApi,
    {
      manual: true,
      onSuccess() {
        toast.success(t('修改成功'));
        reload();
      },
    },
  );

  return {
    list,
    loading,
    loadMore,
    error,
    total,
    reload,
    recommendForm,
    setRecommendForm,
    createInvitationCodeReq,
    modifyInvitationCodeReq,
    memberInvitationInformationData,
    resetPageProps,
  };
};
