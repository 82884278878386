import BaseModal from '@/components/base/baseModal';
import { getInvitationCodeFriendApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { useDisclosure } from '@nextui-org/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ inviteCode, children }: IProps) => {
  const { t } = useTranslation();
  // 添加新推荐弹窗
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { run: getInvitationCodeFriendReq, data: invitationCodeFriendData } =
    useReq(getInvitationCodeFriendApi, {
      manual: true,
    });
  return (
    <>
      <div
        onClick={() => {
          onOpen();
          if (!invitationCodeFriendData) {
            getInvitationCodeFriendReq({
              inviteCode,
            });
          }
        }}
      >
        {children}
      </div>
      {/*添加邀请方式弹窗*/}
      <BaseModal isOpen={isOpen} onClose={onClose} title={t('邀请好友列表')}>
        <div className="px-4 ">
          <div className="my-2 flex justify-between text-auxiliaryTextColor">
            <span>{t('好友ID')}</span>
            <span>{t('时间')}</span>
          </div>
          <div className="min-h-[100px]">
            {(invitationCodeFriendData as any)?.map((item: any) => {
              return (
                <div
                  className="my-2 flex justify-between text-backContrastColor text-sm"
                  key={item?.id}
                >
                  <span>{item?.name}</span>
                  <span>{RenderUtil.formatDate(item?.createTime,"YYYY-MM-DD HH:mm:ss")}</span>
                </div>
              );
            })}
          </div>
        </div>
      </BaseModal>
    </>
  );
};
interface IProps {
  children: ReactNode;
  inviteCode: string;
}
