import {MdiRhombus} from '@/assets/font/MdiRhombus';
import {F7ExclamationmarkCircleFill} from '@/assets/icons/comm/F7ExclamationmarkCircleFill';
import BaseInput from '@/components/base/baseInput';
import BaseModal from '@/components/base/baseModal';
import {getTierListApi} from '@/services/api/requestApi';
import {useReq} from '@/services/net/request';
import {Button, Checkbox, Slider, useDisclosure} from '@nextui-org/react';
import {useMount} from 'ahooks';
import {Dispatch, ReactNode, SetStateAction, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

export default ({
                    children,
                    recommendForm,
                    setRecommendForm,
                    createInvitationCodeReq,
                    memberInvitationInformationData,
                }: IProps) => {
    const {t} = useTranslation();
    // 添加新推荐弹窗
    const {isOpen, onOpen, onClose} = useDisclosure();
    //点击添加推荐中的注意图片弹出的注意信息
    const {
        isOpen: isOpen2,
        onOpen: onOpen2,
        onClose: onClose2,
    } = useDisclosure();

    //等级列表
    const {run: getTierListReq, data: tierListData = []} = useReq(
        getTierListApi,
        {
            manual: true,
        },
    ) as any;
    useMount(() => {
        getTierListReq();
    });

    useEffect(() => {
        tierListData?.sort((a: any, b: any) => a.level - b.level);
    }, [tierListData]);
    return (
        <>
            <div onClick={onOpen}>{children}</div>
            {/*添加邀请方式弹窗*/}
            <BaseModal isOpen={isOpen} onClose={onClose} title={t('添加新推荐')}>
                <div className="px-4 text-foreground">
                    <div className="flex items-center pt-4 text-sm">
            <span className="mr-2">
              {t('您的基础返佣比例')}：
                {memberInvitationInformationData?.rebateLimit}%
            </span>
                        <F7ExclamationmarkCircleFill onClick={onOpen2}/>
                    </div>
                    <div className="my-6">
                        <div className="mb-2 text-sm">{t('备注')}</div>
                        <div className="relative">
                            <BaseInput
                                isClearable
                                value={recommendForm.remark}
                                onChange={(e) =>
                                    setRecommendForm((prevState: any) => {
                                        if (
                                            prevState?.remark?.trim()?.length < 20 ||
                                            e.target.value === ''
                                        ) {
                                            return {
                                                ...prevState,
                                                remark: e.target.value,
                                            };
                                        } else {
                                            return {
                                                ...prevState,
                                            };
                                        }
                                    })
                                }
                                isRequired={false}
                                type="text"
                                className="flex-1 "
                                placeholder={t('请输入备注')}
                                classNames={{
                                    clearButton: 'block opacity-100 right-[44px]',
                                    innerWrapper: 'w-[calc(100%-68px)]',
                                }}
                            />

                            <div className="absolute right-4 top-2 cursor-pointer">
                                {recommendForm.remark?.trim()?.length}/20
                            </div>
                        </div>
                    </div>
                    <div className="my-6">
                        <div className="mb-2 text-sm">{t('设置佣金比例')}</div>
                        <div className="px-2">
                            <Slider
                                color="foreground"
                                size="sm"
                                classNames={{
                                    track: 'border-none',
                                    mark: 'last:!left-[100%]',
                                }}
                                step={1}
                                minValue={1}
                                maxValue={memberInvitationInformationData?.rebateLimit}
                                renderThumb={({index, ...props}) => {
                                    return (
                                        <MdiRhombus
                                            width="20px"
                                            height="20px"
                                            {...props}
                                            className="rounde-none! mt-[1px]"
                                        />
                                    );
                                }}
                                value={recommendForm.rebateRatio}
                                onChange={(value: any) => {
                                    setRecommendForm((prevState) => {
                                        return {
                                            ...prevState,
                                            rebateRatio: +value,
                                        };
                                    });
                                }}
                                className="max-w-md text-foreground"
                            />
                        </div>
                        <div className="flex justify-between text-sm pt-3">
                            <div>
                <span className="text-auxiliaryTextColor mr-2">
                  {t('我的')}
                </span>
                                <span>{recommendForm.rebateRatio}%</span>
                            </div>
                            <div>
                <span className="text-auxiliaryTextColor mr-2">
                  {t('好友')}
                </span>
                                <span>
                  {memberInvitationInformationData?.rebateLimit -
                      recommendForm.rebateRatio}
                                    %
                </span>
                            </div>
                        </div>
                    </div>
                    <Checkbox
                        isSelected={recommendForm.defaultCommend}
                        size="sm"
                        onClick={() => {
                            setRecommendForm((prevState) => {
                                return {
                                    ...prevState,
                                    defaultCommend: !prevState.defaultCommend,
                                };
                            });
                        }}
                    >
                        <span className="">{t('设置为默认推荐')}</span>
                    </Checkbox>
                    <div className="flex justify-between my-6">
                        <Button
                            className="mainColorButton mr-2 !bg-backgroundAuxiliaryColor"
                            onClick={onClose}
                        >
                            {t('取消')}
                        </Button>
                        <Button
                            className="mainColorButton ml-2"
                            onClick={() => {
                                createInvitationCodeReq({
                                    ...recommendForm,
                                    status: 1,
                                    defaultCommend: Number(recommendForm.defaultCommend),
                                    cashbackRatio:
                                        memberInvitationInformationData?.rebateLimit -
                                        recommendForm.rebateRatio,
                                }).then(() => {
                                    onClose();
                                });
                            }}
                        >
                            {t('保存')}
                        </Button>
                    </div>
                </div>
            </BaseModal>
            {/*点击注意事项弹窗*/}
            <BaseModal
                isOpen={isOpen2}
                onClose={onClose2}
                hideCloseButton
                placement="center"
            >
                <div className="px-4 w-[300px]  pb-6">
                    <div className="flex justify-between text-sm text-auxiliaryTextColor mb-3">
                        <span>{t('等级')}</span>
                        <span>{t('返佣比例')}</span>
                    </div>
                    {tierListData?.map((item: any) => {
                        return (
                            <div
                                className="flex justify-between text-sm text-backContrastColor mb-2"
                                key={item.id}
                            >
                <span>
                  {item?.level}
                    {t('级')}
                </span>
                                <span>{item?.rebateLimit}%</span>
                            </div>
                        );
                    })}
                    <Button className="mainColorButton mt-4" onClick={onClose2}>
                        {t('确定')}
                    </Button>
                </div>
            </BaseModal>
        </>
    );
};

interface IProps {
    children: ReactNode;
    recommendForm: {
        remark: string;
        rebateRatio: number;
        defaultCommend: boolean;
    };
    setRecommendForm: Dispatch<
        SetStateAction<{
            remark: string;
            rebateRatio: number;
            defaultCommend: boolean;
        }>
    >;
    createInvitationCodeReq: any;
    memberInvitationInformationData: any;
}
