import { LineMdList } from '@/assets/icons/comm/LineMdList';
import { BasilEditOutline } from '@/assets/icons/mine/BasilEditOutline';
import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseTopNav from '@/components/base/baseTopNav';

import AddInvitationCode from '@/pages/invite/components/AddInvitationCode';
import RecommendedSettingsEditRemark from '@/pages/invite/recommendedSettings/components/RecommendedSettingsEditRemark';
import RecommendedSettingsFriends from '@/pages/invite/recommendedSettings/components/RecommendedSettingsFriends';
import useStateHooks from '@/pages/invite/recommendedSettings/useStateHooks';
import { FormatUtils } from '@/utils/format';
import { Divider } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const {
    list,
    loading,
    loadMore,
    error,
    total,
    recommendForm,
    setRecommendForm,
    createInvitationCodeReq,
    modifyInvitationCodeReq,
    memberInvitationInformationData,
    resetPageProps,
  } = useStateHooks();
  return (
    <>
      <BaseTopNav title={t('好友推荐项目|推荐好友&赢取奖励')} />
      <div className="px-4 text-sm">
        <div className="flex justify-center  text-base py-2 font-bold mb-6">
          <h3 className="text-foreground">{t('我的推荐码')}</h3>
        </div>
        <PullToRefresh
          loadMore={loadMore}
          total={total}
          list={list}
          error={error}
          loading={loading}
          {...resetPageProps}
        >
          {list?.map((item: any) => {
            return (
              <div className="text-auxiliaryTextColor" key={item?.id}>
                <div className="flex justify-between items-center mb-2  text-xs">
                  <span className="text-base text-backContrastColor font-bold">
                    {item?.inviteCode}
                  </span>
                  {+item?.defaultCommend === 1 && (
                    <div className="bg-primary/40 text-primary rounded-md px-2 py-1">
                      {t('默认')}
                    </div>
                  )}
                  {+item?.defaultCommend === 0 && (
                    <div
                      className="bg-backgroundAuxiliaryColor cursor-pointer  text-backContrastColor rounded-md px-2 py-1"
                      onClick={() => {
                        modifyInvitationCodeReq({
                          id: item?.id,
                          defaultCommend: 1,
                        });
                      }}
                    >
                      {t('设为默认')}
                    </div>
                  )}
                </div>
                <div className="flex justify-between items-center mb-2  text-xs">
                  <span>{t('您的返佣比例')}</span>
                  <span className="text-backContrastColor">
                    {item?.rebateRatio}%
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2  text-xs">
                  <span> {t('推荐链接')}</span>
                  <span className="text-backContrastColor flex items-center">
                    <span className="mr-1">{item?.referralLink}</span>
                    <PhCopyFill
                      className="cursor-pointer"
                      onClick={() =>
                        FormatUtils.copyText(item?.referralLink || '')
                      }
                    />
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2  text-xs">
                  <span>{t('备注')}</span>
                  <RecommendedSettingsEditRemark
                    remark={item?.remark}
                    id={item?.id}
                    modifyInvitationCodeReq={modifyInvitationCodeReq}
                  >
                    <span className="text-backContrastColor flex items-center">
                      <span className="mr-1">{item?.remark}</span>
                      <BasilEditOutline className="cursor-pointer" />
                    </span>
                  </RecommendedSettingsEditRemark>
                </div>
                <div className="flex justify-between items-center mb-2  text-xs">
                  <span>{t('好友数')}</span>
                  <span className="text-backContrastColor flex items-center">
                    <span className="mr-1">{item?.friendCount}</span>
                    <RecommendedSettingsFriends inviteCode={item?.inviteCode}>
                      <LineMdList className="cursor-pointer" />
                    </RecommendedSettingsFriends>
                  </span>
                </div>
                <Divider className="my-4 pb-0 !bg-backgroundAuxiliaryColor" />
              </div>
            );
          })}
        </PullToRefresh>

        <AddInvitationCode
          memberInvitationInformationData={memberInvitationInformationData}
          createInvitationCodeReq={createInvitationCodeReq}
          recommendForm={recommendForm}
          setRecommendForm={setRecommendForm}
        >
          <div className="px-4 fixed sm:relative bottom-0 left-0 w-full  pb-[30px] bg-background">
            <button className="mainColorButton !p-4" type="submit">
              {t('添加新推荐')}
            </button>{' '}
          </div>
        </AddInvitationCode>
      </div>
    </>
  );
};
