import BaseInput from '@/components/base/baseInput';
import BaseModal from '@/components/base/baseModal';
import { Button, useDisclosure } from '@nextui-org/react';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ children, remark, id, modifyInvitationCodeReq }: IProps) => {
  const { t } = useTranslation();
  // 添加新推荐弹窗
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [remarkText, setRemarkText] = useState(remark ?? '');

  useEffect(() => {
    if (isOpen) {
      setRemarkText(remark ?? '');
    }
  }, [isOpen]);

  return (
    <>
      <div onClick={onOpen}>{children}</div>
      {/*添加邀请方式弹窗*/}
      <BaseModal isOpen={isOpen} onClose={onClose} title={t('修改备注')}>
        <div className="px-4  text-foreground">
          <div className="my-6">
            <div className="mb-2 text-sm ">{t('备注')}</div>
            <div className="relative">
              <BaseInput
                isClearable
                value={remarkText}
                onChange={(e) => {
                  setRemarkText(e.target.value);
                }}
                isRequired={false}
                type="text"
                placeholder={t('请输入备注')}
                className="flex-1 "
                maxLength={20}
                classNames={{
                  clearButton: 'block opacity-100 right-[44px]',
                  innerWrapper: 'w-[calc(100%-68px)]',
                }}
              />

              <div className="absolute right-4 top-2 cursor-pointer">
                {remarkText?.trim()?.length ?? 0}/20
              </div>
            </div>
          </div>
          <div className="flex justify-between my-6">
            <Button
              className="mainColorButton mr-2 !bg-backgroundAuxiliaryColor"
              onClick={onClose}
            >
              {t('取消')}
            </Button>
            <Button
              className="mainColorButton ml-2"
              onClick={() => {
                modifyInvitationCodeReq({
                  id,
                  remark: remarkText,
                });
              }}
            >
              {t('保存')}
            </Button>
          </div>
        </div>
      </BaseModal>
    </>
  );
};

interface IProps {
  children: ReactNode;
  remark: string;
  id: string;
  modifyInvitationCodeReq: any;
}
